
.page-connector-configuration {
    height: 100vh;

    .login-stack {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .config-stack {
        max-width: 520px;
    }

    .config-description {
        margin: 20px 0;
    }

    .config-title {
        font-weight: 500;
    }

    .config-icon {
        font-size: 48px;
        line-height: normal;
        color: green;
        margin-bottom: 10px;
    }
}