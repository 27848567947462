
.page-login, .page-callback, .page-logout {
    height: 100vh;

    .login-stack {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}